import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dental from "src/packages/dental/Dental";
const DentalRouter = () => {
    return (<Routes>
                <Route path={`employee`} element={<Dental />}/>
                <Route path={`*`} element={<Navigate to={`/dental/employee`} replace/>}/>
        </Routes>);
};
export default DentalRouter;
